.container-guia {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  color: #333;
  background-color: #A8B88D;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.section {
  margin-bottom: 30px;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section-title {
  font-size: 22px;
  margin-bottom: 15px;
  display: flex;
  text-align: center;
}

.section-text {
  font-size: 16px;
  line-height: 1.5;
  text-align: justify;
  color: #405a41;
}

.recycling-bins {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; /* Adicionado para responsividade */
  margin-top: 20px;
  gap: 15px; /* Adicionado para espaçamento entre os itens */
}

.bin {
  text-align: center;
  flex: 1 1 120px; /* Itens se adaptam com largura mínima de 120px */
}

.bin-label {
  font-weight: bold;
  margin-top: 8px;
  display: block;
}

.bin-color {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.icone-lixo {
  width: 45px;
}

.blue {
  background-color: #2196f3;
}
.red {
  background-color: #f44336;
}
.green {
  background-color: #4caf50;
}
.yellow {
  background-color: #ffeb3b;
}
.brown {
  background-color: #795548;
}
.gray {
  background-color: #9e9e9e;
}

.lista-beneficios {
  list-style-type: none;
  padding: 0;
}

.lista-beneficios li {
  margin: 10px 0;
  font-size: 16px;
  color: #405a41;
}

.recyclables {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Adicionado para responsividade */
  gap: 20px;
}

.recyclables-list,
.non-recyclables-list {
  width: 45%;
}

.subsection-title {
  font-size: 18px;
  color: #405a41;
  margin-bottom: 10px;
  text-align: center;
}

.recyclables-list ul,
.non-recyclables-list ul {
  list-style-type: none;
  padding: 0;
}

.recyclables-list li,
.non-recyclables-list li {
  margin: 8px 0;
  font-size: 16px;
  text-align: center;
}

.ul-materiais-reciclagem {
  display: flex;
  flex-wrap: wrap; /* Adicionado para responsividade */
  margin: 10px 15px;
  list-style: none;
  gap: 5px;
  text-align: center;
}

.li-materiais-reciclagem {
  border: 1px solid #405a41;
  border-radius: 8px;
  padding: 10px 15px;
  margin: 8px 0;
  background-color: #f9f9f9;
  font-size: 14px;
  color: #333;
  transition: background 0.3s;
  flex: 1 1 calc(50% - 10px); /* Itens ocupam metade da largura com gap */
}

.li-materiais-reciclagem:hover {
  background-color: #658166;
  color: #ffffff;
}

.beneficios-coleta-img {
  background-color: #658166;
}

.dicas-reciclagem {
  text-align: justify;
  color: #405a41;
}

.dicas-reciclagem li {
  list-style-type: none;
  margin-bottom: 8px;
  font-size: 16px;
}

.dicas-reciclagem h1 {
  font-size: 18px;
  color: #405a41;
}

.creditos-faq {
  font-size: 8px;
  text-decoration: none;
  color: #333;
}

.container-creditos h1 {
  font-size: 10px;
}

/* Responsividade */
@media (max-width: 768px) {
  .recyclables-list,
  .non-recyclables-list {
    width: 100%; /* Ocupam largura total em telas menores */
  }

  .li-materiais-reciclagem {
    flex: 1 1 100%; /* Cada item ocupa largura total em telas menores */
  }

  .recycling-bins {
    justify-content: center;
    gap: 20px;
  }

  .section-title {
    font-size: 20px;
    text-align: center;
  }

  .section-text {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .section-title {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .section-text {
    font-size: 12px;
  }

  .bin-color {
    width: 50px;
    height: 50px;
  }

  .icone-lixo {
    width: 40px;
  }

  .li-materiais-reciclagem {
    font-size: 12px;
    padding: 8px;
  }
}
