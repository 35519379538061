/* Estilo geral da página*/
* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

html,
body,
#root {
  height: 100%; /* Garante que o root ocupe toda a altura da viewport */
  margin: 0;
}

/* Corpo */
body {
  background-image: url("./assets/images/index.png");
  background-size: cover;
  background-position: top center; /* Garante que a imagem de fundo fique alinhada */
  background-repeat: no-repeat;
  background-attachment: scroll; /* Faz como que a imagem de fundo role junto com a tela */
  min-height: 100vh;
  background-color: #dcebba;
  display: flex;
  flex-direction: column;
}

#root {
  height: 100%;
  width: 100%;
}

.App {
  display: flex;
  flex-direction: column; /* Organização vertical: conteúdo + footer */
  min-height: 100vh; /* Força o App a ocupar a altura da tela */
}

.container-total {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.conteudo-footer {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Garante que a página ocupe toda a altura da tela */
}

.texto-acima {
  display: flex;
  flex-direction: column;
  /* Garante que a página ocupe toda a altura da tela */
  align-items: center;
  text-align: center;
}

/* Conteúdo principal do index*/

.painelCentral {
  /*Textos da página principal*/
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  margin: 40px 80px 50px 80px;
}

.painelCentral h1 {
  /* Adiciona um espaçamento entre o texto principal e o sub-texto */
  margin-bottom: 15px;
}

.pontoProximo {
  /* Texto principal */
  font-size: 44px;
  color: #435533;
}

.encontrarPontos {
  /*sub-texto */
  font-size: 26px;
  color: #000000;
  /* color: #405a41; */
  opacity: 0.6;
}

.btnPainel {
  /* Botões de busca*/
  width: 150px;
  height: 45px;
  border-radius: 9px;
  background-color: #55703f;
  color: #ffffff;
  border-style: none;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  margin: 0px 0px 15px 20px;
  cursor: pointer;
}

.btnPainel:hover {
  background-color: #2a4f2d;
}

.btnPrincipais {
  /* Container onde estão os botões de busca */
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  margin: 0px 80px 0px 60px;
}

/* Sugestão de ponto na pag index*/

.sugerir {
  font-size: 18px;
  color: #405a41;
  text-decoration: underline;
  margin-bottom: 45px;
  cursor: pointer;
}

.sugerir a {
  color: #405a41;
  text-decoration: underline;
}

.sugerir a:visited {
  color: #405a41;
}

.containerSugerir {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.containerSugerir p {
  color: #435533;
  font-size: 30px;
  font-weight: bold;
}

/* Fim da sugestão de ponto na pag index*/
/* Fim do conteúdo principal do index*/

/* Resultados */

.tela-resultados-container h2 {
  text-align: left;
  justify-self: flex-start;
  color: var(--primary-color);
  margin-bottom: 1rem;
  margin-left: 1%;
  padding-left: 4rem;
  color: #356037;
}

.resultados-mapa-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.resultados-container {
  padding-left: 4rem;
  background-color: var(--background-color);
  flex: 1;
  margin-left: 1%;
  margin-right: 5%;
  margin-bottom: 5%;
  overflow-y: auto;
  overflow-x: hidden;
}

.resultados-container .card {
  /* background-color: var(--secondary-color); */
  background-color: white;
  border-radius: 8px;
  padding: 1rem;
  margin: 1rem 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.resultados-container .card h2 {
  padding-left: 0;
  margin: 1% 2% 2% 2%;
}

.resultados-container .card p {
  margin-bottom: 0.5rem;
  color: black;
}

.resultados-container .card a {
  /* color: var(--primary-color); */
  color: black;
  font-weight: bold;
  display: inline-block;
}

.resultados-container .card a:hover {
  text-decoration: underline;
}

.mapa {
  flex: 1;
  align-self: center;
  align-content: center;
  height: 100%;
  margin-right: 5%;
  padding-bottom: 10%;
  border-radius: 15px;
}

/* Estilos para a página de pontos sugeridos */
.suggested-points-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  text-align: left;
}

.suggested-point {
  width: 600px;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #e9f1e2;
}

.suggested-point p {
  margin: 5px 0;
  color: #405a41;
}

.btn-sugeridos {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-self: center;
}

.suggested-point button {
  width: 150px;
  height: 45px;
  font-size: 18px;
  color: #fff;
  background-color: #55703f;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.suggested-point button:hover {
  background-color: #65844c;
}

.suggested-point button:last-of-type {
  background-color: #e24959;
}

.suggested-point button:last-of-type:hover {
  background-color: #e75e65;
}

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content .close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.popup-content {
  margin: auto;
  justify-content: space-between;

  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  max-height: 80vh; /* Limita a altura do popup */
  overflow-y: auto; /* Adiciona rolagem vertical */
  position: relative;
}

.popup-content .close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.empresa-item {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.empresa-item p {
  color: #435533;
}

.ong-item {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.ong-item p {
  color: #435533;
}

/* Modal */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  text-align: left;
}

.popup-content-title {
  color: #435533;
  display: flex;
  font-size: 15px;
  margin-bottom: 1rem;
}

.popup-content label {
  display: block;
  margin: 0.5rem 0 0.3rem;
  color: #000000;
}

.popup-content input,
.popup-content select {
  width: 100%;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-bottom: 1rem;
}

.popup-content .close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  color: var(--text-color);
  cursor: pointer;
}

/* Botão Buscar no Modal */
.popup-content button {
  background-color: #55703f;
  color: #fff;
  padding: 0.8rem;
  width: 100%;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 20px;
}

.popup-content button:hover {
  background-color: #65844c;
}

.material-checkboxes {
  display: flex;
  flex-direction: row;
  gap: 10px; /* Espaço entre cada label */
  flex-wrap: wrap; /* Faz os itens "pularem" para a linha de baixo quando necessário */
  text-align: center;
}

.radioEmpresa {
  display: flex;
  align-items: baseline;
  gap: 20px;
}

.popup-title {
  color: #435533;
  font-size: 25px;
}

.material-popup {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.material-popup input {
  vertical-align: middle;
  margin: 0;
  display: inline-block;
  margin-right: 5px;
}

.material-popup label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.btn-sugeridos {
  color: white;
  display: flex;
  flex-direction: row !important;
  justify-content: center !important;
  gap: 10px;
}

.action-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.action-buttons button {
  width: 150px;
  font-size: 18px;
}

/* Responsividade geral */
@media (max-width: 1024px) {
  .painelCentral {
    width: 70%;
    margin: 20px auto;
  }

  .btnPainel {
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }

  .resultados-mapa-container {
    flex-direction: column;
    height: auto;
  }

  .resultados-container {
    margin: 0 auto;
    width: 90%;
    padding-left: 1rem;
  }

  .mapa {
    height: 400px;
    margin: 20px auto;
    width: 90%;
  }

  .suggested-points-container {
    max-width: 90%;
    padding: 15px;
  }

  .popup-content {
    width: 90%;
    max-width: 350px;
    padding: 15px;
  }
}

/* Responsividade para telas menores */
@media (max-width: 768px) {
  .painelCentral {
    width: 90%;
  }

  .pontoProximo {
    font-size: 32px;
  }

  .encontrarPontos {
    font-size: 18px;
  }

  .btnPainel {
    width: 120px;
    height: 40px;
    font-size: 12px;
  }

  .popup-content {
    width: 95%;
    padding: 10px;
  }

  .resultados-container .card {
    padding: 10px;
    margin: 10px 0;
  }

  .material-checkboxes {
    gap: 5px;
    justify-content: center;
  }

  .radioEmpresa {
    flex-direction: column;
    align-items: flex-start;
  }

  .popup-title {
    font-size: 20px;
  }

  .btn-sugeridos button {
    width: 120px;
    height: 40px;
    font-size: 16px;
  }

  .suggested-point {
    width: 400px;
  }

  .action-buttons button {
    width: 120px;
    font-size: 14px;
  }
}

/* Responsividade para dispositivos móveis */
@media (max-width: 480px) {
  .painelCentral {
    width: 100%;
  }

  .pontoProximo {
    font-size: 22px;
    padding-left: 10px;
  }

  .encontrarPontos {
    font-size: 14px;
  }

  .btnPrincipais {
    margin: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-right: auto;
    gap: 10px;
  }

  .btnPainel {
    width: 100px;
    margin: 0;
    font-size: 10px;
  }

  .mapa {
    height: 300px;
  }

  .resultados-container {
    padding: 10px;
  }

  .suggested-points-container {
  }

  .suggested-point {
    width: 250px;
    justify-self: center;
  }

  .suggested-point button {
    font-size: 12px;
  }

  .popup-content {
    width: 95%;
    max-width: 300px;
  }

  .popup-title {
    font-size: 18px;
  }

  .material-checkboxes {
    gap: 5px;
  }

  .action-buttons button {
    width: 100px;
    font-size: 14px;
  }
}

.btn-add-ponto {
  margin-top: 20px;
}
