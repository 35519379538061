.perfil-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  width: 600px;
  max-width: 900px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.icone-perfil {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-bottom: 10px;
  object-fit: cover;
}

.perfil-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.perfil-info button {
  width: 150px;
  height: 45px;
  margin-top: 10px;
  font-size: 18px;
  color: #fff;
  background-color: #55703F;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
}

.info-cadastro-principais {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
}

/* Responsividade para inputs e labels */
.info-cadastro-principais label {
  flex: 1 1 100%; /* Garante que os labels ocupem toda a linha em telas pequenas */
  margin-bottom: 5px;
  font-weight: bold;
  color: #435533;
}

.info-cadastro-principais input,
.info-cadastro-principais select,
.info-cadastro-principais textarea {
  flex: 1 1 48%; /* Mantém dois elementos por linha em telas médias */
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.botoes-alterar-senha {
  display: flex;
  gap: 10px;
}

.info-cadastro-principais textarea {
  height: 80px;
  resize: vertical;
}

.info-materiais label {
  font-size: 12px;
}

.btn-perfil-container {
  display: flex;
  flex-wrap: wrap; /* Permite que os botões fiquem em várias linhas em telas pequenas */
  justify-content: center;
  gap: 15px; /* Reduz o gap em telas menores */
}

.btn-perfil {
  background-color: #435533;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}

.btn-perfil:hover {
  background-color: #2e4224;
}

.botoes-alterar-perfil {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}


/* Ajuste para botões em telas pequenas */
@media (max-width: 768px) {
  .perfil-container {
    width: 400px;
    padding: 15px;
  }

  .icone-perfil {
    width: 100px;
    height: 100px;
  }

  .btn-perfil {
    padding: 8px 16px;
    font-size: 14px;
  }

  .perfil-info button {
    width: 120px;
    font-size: 16px;
  }
}

.info-materiais input {
  vertical-align: middle;
  margin: 0;
  display: inline-block;
  margin-right: 5px;
}

.info-materiais label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px; /* Ajusta o tamanho da fonte */
}

/* Ajuste para telas muito pequenas */
@media (max-width: 480px) {
  .perfil-container {
    width: 250px;
  }

  .info-cadastro-principais input,
  .info-cadastro-principais select,
  .info-cadastro-principais textarea {
    flex: 1 1 100%; /* Cada elemento ocupa toda a largura */
    font-size: 14px;
  }

  .perfil-info button {
    width: 100px;
    font-size: 12px;
  }
}

