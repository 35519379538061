.container-validados {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    text-align: left;
}

.btn-validated {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
}

.ponto-validado {
    width: 600px;
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #e9f1e2;
}


.ponto-validado p {
    margin: 5px 0;
    color: #405a41;
    font-size: 16px;
    /* Tamanho padrão */
}

.ponto-validado button {
    width: 150px;
    height: 45px;
    font-size: 18px;
    color: #fff;
    background-color: #55703F;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
}


.ponto-validado button:hover {
    background-color: #65844c;
}

.ponto-validado button:last-of-type {
    background-color: #e24959;
}

.ponto-validado button:last-of-type:hover {
    background-color: #e75e65;
}

/* Ajuste para telas menores */
@media (max-width: 768px) {
    .container-validados {
        padding: 15px;
    }

    .ponto-validado {
        width: 400px;
        padding: 10px;
        /* Reduz o padding em telas menores */
        font-size: 14px;
        /* Ajusta o tamanho da fonte */
    }

    .ponto-validado p {
        font-size: 14px;
        /* Reduz o tamanho da fonte em telas menores */
    }

    .ponto-validado button {
        width: 120px;
        height: 40px;
        font-size: 16px;
    }
}


/* Ajuste para botões alinhados em telas muito pequenas */
@media (max-width: 480px) {
    .ponto-validado {
        width: 250px;
    }

    .ponto-validado button {
        display: block;
        width: 100px;
        font-size: 14px;
    }
}