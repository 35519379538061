h2 {
    font-size: 24px;
    color: #405A41;
    margin: 20px;
    text-align: start;
    font-weight: bold;
    text-transform: uppercase;
}

.accordion {
    margin: auto;
    width: 90%;
    max-width: 800px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow: hidden;
    font-family: "Quicksand", sans-serif;
    background-color: #55703f;
    margin-bottom: 5rem;
    margin-top: 1rem;
    flex: 1;
}

.accordion__label,
.accordion__content {
    padding: 14px 20px;
}

.accordion__label {
    display: block;
    color: #ffffff;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    transition: background 0.1s;
}

.accordion__label:hover {
    background: rgba(0, 0, 0, 0.1);
}

.accordion__label::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    width: 12px;
    height: 6px;
    background-image: url('data:image/svg+xml;utf8,<svg width="100" height="50" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,0 50,50" style="fill:%23FFFFFF99;" /></svg>');
    background-size: contain;
    transition: transform 0.4s;
}

.accordion__content {
    background: #ffffff;
    line-height: 1.6;
    font-size: 0.85em;
    display: none;
    text-align: justify;
}

.accordion__input {
    display: none;
}

.accordion__input:checked ~ .accordion__content {
    display: block;
}

.accordion__input:checked ~ .accordion__label::after {
    transform: translateY(-50%) rotate(0.5turn);
}

.heroFAQ {
    font-size: 24px;
    color: #1c0212;
    margin: 20px;
    text-align: center;
    
}

.paragrafo-faq {
    color: #111511;
}

.accordion p {
    color: #111511;
}

/* Responsividade */
@media (max-width: 768px) {
    h2 {
        font-size: 20px;
        margin: 15px;
        text-align: center;
    }

    .heroFAQ {
        font-size: 20px;
        margin: 15px;
        text-align: justify;
    }

    .accordion {
        width: 95%;
        margin-bottom: 4rem;
    }

    .accordion__label {
        font-size: 0.9em;
    }

    .accordion__content {
        font-size: 0.75em;
    }
}

@media (max-width: 480px) {
    h2 {
        font-size: 18px;
        margin: 10px;
        text-align: center;
    }

    .heroFAQ {
        font-size: 18px;
        margin: 10px;
        text-align: justify;
    }

    .accordion {
        width: 100%;
        margin-bottom: 3rem;
    }

    .accordion__label {
        font-size: 0.8em;
    }

    .accordion__content {
        font-size: 0.7em;
    }
}
