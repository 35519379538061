.login-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 20px;
}

h2 {
  font-size: 24px;
  color: #405a41;
  margin: 20px;
  text-align: center; /* Altera para centralizado em dispositivos menores */
  font-weight: bold;
  text-transform: uppercase;
}

form {
  margin: auto;
  width: 600px;
  height: auto;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 1em;
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adiciona um leve sombreamento */
}

label {
  display: flex;
  align-items: start;
  margin-right: 18px;
  margin-bottom: 5px;
  font-size: 17px;
  color: #000000;
}

input[type="email"],
input[type="password"] {
  width: calc(100% - 12px);
  height: 50px;
  padding: 0.65em;
  border-radius: 5px;
  margin-bottom: 1.3em;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

button[type="submit"] {
  width: 150px;
  height: 45px;
  color: #ffffff;
  background-color: #55703f;
  text-align: center;
  border-radius: 8px;
  box-shadow: none;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
  border: none;
}

.botao-cadastro {
  text-align: center !important; 
  justify-items: center !important;
  vertical-align: middle !important;
}

p {
  font-size: 22px;
  color: #9a9999;
  margin-bottom: 0.4em;
  text-align: center; /* Centraliza o texto em dispositivos menores */
}

.cadastro-redirect {
  text-align: center; /* Centraliza em dispositivos menores */
  margin-bottom: 20px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}

.cadastro-redirect a {
  color: rgba(0, 0, 0, 0.5);
}

.cadastro-redirect a:visited {
  color: rgba(0, 0, 0, 0.5);
}

.cadastro-redirect a:hover {
  text-decoration: underline;
}

/* Responsividade */
@media (max-width: 768px) {

  form {
    padding: 1em;
    margin-top: 15px;
    margin-bottom: 40px;
    width: 400px;
  }

  h2 {
    font-size: 20px;
  }

  label {
    font-size: 16px;
  }

  input[type="email"],
  input[type="password"] {
    height: 45px;
    font-size: 14px;
  }

  .container-botoes button {
    width: 120px !important;
    font-size: 16px !important;
  }

  p {
    font-size: 18px;
  }

  .cadastro-redirect {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  form {
    width: 250px;
  }

  h2 {
    font-size: 18px;
  }

  input[type="email"],
  input[type="password"] {
    height: 40px;
    font-size: 14px;
  }

  .container-botoes button {
    font-size: 14px !important;
    width: 100px !important;
    height: 40px !important;
  }

  .cadastro-redirect {
    font-size: 10px;
  }
}
