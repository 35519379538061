/* Estilo padrão (para telas maiores) */
.cadastro-container {
  width: 800px;
  margin: 0 auto;
  padding: 20px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea,
select {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  margin-bottom: 1.3em;
}

button[type="submit"] {
  background-color: #405a41;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.tipo_transacao {
  margin-top: 1.3em;
}

.form-tipo-transacao {
  margin-bottom: 1.3em;
  border-radius: 5px;
  margin-left: 5px;
}

.form-cadastro-dropdown {
  margin-bottom: 1.3em;
  border-radius: 5px;
  width: calc(100% - 12px);
}

.select-tipo-cadastro {
  margin-left: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.text-descricao {
  border-radius: 5px;
  width: calc(100% - 12px);
}

.cadastro-container p {
  font-size: 12px;
}

.step-indicator {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  position: relative;
  padding: 10px 20px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;
}

.step .circle {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #ccc;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 5px;
  transition: all 0.3s ease;
}

.step.active .circle {
  background-color: #55703f;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transform: scale(1.1);
}

.step-indicator::before {
  content: "";
  position: absolute;
  top: 20px;
  left: 10%;
  right: 10%;
  height: 2px;
  background-color: #ccc;
  z-index: -1;
}

.step.active ~ .step-indicator::before {
  background-color: #55703f;
}

.multi-select .css-t3ipsp-control {
  box-shadow: none !important;
  border-color: #ccc !important;
}
.multi-select .css-13cymwt-control {
  width: calc(100% - 12px);
}

.btn-cadastro {
  margin-top: 15px;
}

.containerBotoes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 12px);
  padding: 10px;
}

.container-form {
  display: grid;
  flex-direction: column;
}

.botao-proximo {
  justify-self: center;
}

.containerBotoes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  margin-top: 1.3rem;
}

.containerBotoes button {
  align-items: end;
  width: 150px;
  height: 45px;
  color: #ffffff;
  background-color: #55703f;
  text-align: center;
  border-radius: 8px;
  box-shadow: none;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
  border: none;
  margin-top: 8px;
}

.containerBotoes button:hover {
  background-color: #2a4f2d;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .cadastro-container {
    width: 400px;
  }

  input[type="text"] {
    height: 45px;
    padding: 0.6em;
    font-size: 14px;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  textarea,
  select {
    font-size: 0.9rem;
    padding: 10px;
  }

  .form-tipo-transacao,
  .form-cadastro-dropdown {
    margin-bottom: 1em;
  }

  .select-tipo-cadastro {
    margin-left: 0;
    width: 100%;
  }

  .text-descricao {
    height: 80px;
    padding: 0.6em;
  }

  .material-options {
    gap: 8px;
    margin-top: 2px;
    margin-bottom: 1em;
  }

  .material-options label {
    font-size: 14px;
    gap: 6px;
  }

  .cadastro-container p {
    font-size: 11px;
    line-height: 1.5;
  }

  button[type="submit"] {
    font-size: 0.9rem;
    padding: 12px;
  }

  .botao-cadastro {
    width: 100px;
  }

}

/* Responsividade para telas pequenas (smartphones) */
@media (max-width: 480px) {
  .cadastro-container {
    width: 300px;
    padding: 10px;
  }

  .cadastro-container p {
    font-size: 10px;
    line-height: 1.4;
  }


  input[type="text"] {
    height: 40px;
    padding: 0.5em;
    font-size: 12px;
  }

  .form-tipo-transacao,
  .form-cadastro-dropdown {
    margin-bottom: 0.8em;
  }

  .select-tipo-cadastro {
    margin-left: 0;
    width: 100%;
    font-size: 12px;
  }

  .text-descricao {
    height: 70px;
    padding: 0.5em;
  }

  .material-options {
    gap: 6px;
    margin-top: 1px;
    margin-bottom: 0.8em;
  }

  .material-options label {
    font-size: 12px;
    gap: 5px;
  }

  .cadastro-container p {
    font-size: 10px;
    line-height: 1.4;
  }
}

.divBotoes {
  display: flex;
  justify-content: space-between;
}

/* Responsividade para telas médias (tablets) */
@media (max-width: 768px) {
  .cadastro-container {
    padding: 15px;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  textarea,
  select {
    font-size: 0.9rem;
    padding: 10px;
  }

  button[type="submit"] {
    font-size: 0.9rem;
    padding: 12px;
  }
}

/* Responsividade para telas pequenas (smartphones) */
@media (max-width: 480px) {
  .cadastro-container {
    padding: 10px;
  }

  .material-options {
    flex-direction: column;
    gap: 8px;
  }

  button[type="submit"] {
    font-size: 0.8rem;
    padding: 10px;
  }

  .select-tipo-cadastro,
  .form-cadastro-dropdown,
  .form-tipo-transacao {
    font-size: 0.9rem;
    padding: 8px;
  }

  .text-descricao {
    height: 70px;
    padding: 0.5em;
  }
}
