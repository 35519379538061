.titulo-sobre-nos {
  margin-top: 8px;
  font-size: 25px;
  color: #3e583c;
  text-align: center;
}

.card-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap; /* Ajusta os ícones em linhas, se necessário */
}

.card-icon a {
  display: inline-block;
}

.card-icon img {
  margin-top: 10px;
  width: 30px;
  height: 30px;
  transition: transform 0.3s ease;
}

.card-icon img:hover {
  transform: scale(1.1);
}

.card-sobre-nos {
  color: #ffffff;
  border-radius: 7px;
  text-align: center;
  width: 220px;
  height: auto;
  transition: 0.3s ease-in;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  padding: 15px;
  background-color: #a8b88d;
}

/* Responsividade para os cards */
@media (max-width: 768px) {
  .card-sobre-nos {
    width: 90%; /* Ajusta a largura em telas menores */
    margin: 10px auto; /* Centraliza os cards */
  }
}

.descricao-sobre-nos {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  color: #333;
  background-color: #A8B88D;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
  text-align: justify;
}

/* Responsividade para descrição */
@media (max-width: 768px) {
  .descricao-sobre-nos {
    padding: 15px; /* Reduz o padding */
    font-size: 14px; /* Diminui o tamanho da fonte */
  }
}

@media (max-width: 480px) {
  .descricao-sobre-nos {
    font-size: 12px; /* Fonte menor para dispositivos muito pequenos */
    padding: 10px; /* Mais compacto */
  }
}
